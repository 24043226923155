import React from 'react'
import Loading from '../../components/spinner'
import { withTranslation } from '../../lib/translate'
import Layout from '../../components/layout'
import { Title, SmallText, Spacer, NormalText } from '../../components/common'
import { IonRow, IonCol, IonButton, IonAlert, IonGrid } from '@ionic/react'
// import Box, { BoxHeader } from '../../components/box'
import OrderContent from '../../components/orderContent'
import { createNewBasketInstance } from '../../lib/basket'
import Basket from '../../lib/basket'
import { checkCancelOrder, setOrdersProp } from '../../store/orders/actions'
import moment from '../../lib/moment'
import { forwardTo, visaEndingDrawText } from '../../lib/utils'
import { connect } from 'react-redux'
import { getConfig } from '../../appConfig'
import AmountPaid from '../../components/amountPaid'
import './index.css'

class HistoryDetails extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			isCancel: false,
			orderId: null,
			restaurantId: null
		}
	}

	componentDidMount() {
		const { location } = this.props
		const orderArr = location?.state?.order
		const now = moment()
		let cutoffTime = orderArr ? orderArr.cutoff_time : now
		let cutoffTimeMoment = new moment(cutoffTime, 'YYYY-MM-DD HH:mm:ss')
		const utcOffset = cutoffTimeMoment.utcOffset()
		cutoffTimeMoment = cutoffTimeMoment.add('minutes', utcOffset)
		if (now.isBefore(cutoffTimeMoment)) {
			if (orderArr.status !== 'REFUNDED') {
				this.setState({ isCancel: true, orderId: orderArr.id, restaurantId: orderArr.restaurant_id })
			}
		}
	}


	backHandler = () => {
		this.setState({ orderId: null, isCancel: false, restaurantId: null })
		forwardTo('/dashboard')
		forwardTo('/history', { tab: 'order' })
	}

	formatPaymentString = (str) => {
		let splitedStr = str.split(' ').splice(2).join(' ')
		return splitedStr
	}

	cancelOrder = () => {
		const { orderId, isCancel, restaurantId } = this.state
		if (isCancel) {
			this.props.dispatch(checkCancelOrder(
				orderId,
				restaurantId
			))
			this.setState({ orderId: null, isCancel: false, restaurantId: null })
		}
	}

	handleCancelOrderModal = (flag) => {
		this.props.dispatch(setOrdersProp('cancelOrderModal', flag ))
	}

	drawContentTitle = (__, deliveryOption, id, orderType) => {
		if (orderType === 'Outpost Drop-Off') {
			return <Title>{ __('Drop Point')} { __('Order')} #{ id }</Title>
		} else if (orderType === 'Delivery') {
			return <Title><strong>{ __('Delivery')} { __('Order')} #{ id }</strong></Title>
		} else {
			return <Title>{ __(orderType)} { __('Order')} #{ id }</Title>
		}
	}

	drawContent = (__, order, orderType, basketInstance, orderCompletePage) => {
		if (orderType === 'Click & Collect') {
			return (
				<>
					<IonRow>
						<IonCol size="5" className='paddLR'><SmallText tag="strong" className="bold">{ __('Collect from') }:</SmallText></IonCol>
						<IonCol className="righted"><SmallText color="primary" className="thiner-text">{ order.restaurant_name }</SmallText></IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="5" className='paddLR'><SmallText tag="strong" className="bold">{ __('Collect at') }:</SmallText></IonCol>
						<IonCol className="righted"><SmallText color="primary" className="thiner-text">{ basketInstance.formatOrderTime(true, 'DD MMM YYYY [at] h:mm a') }</SmallText></IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="5" className='paddLR'><SmallText tag="strong" className="bold">{ __('Order placed on:') }</SmallText></IonCol>
						<IonCol className="righted"><SmallText color="primary" className="thiner-text"> { moment(order.created_at).format(/* 'ddd DD MMMM YYYY [at] LT' */ 'DD MMM YYYY [at] h:mm a')} </SmallText></IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="5" className='paddLR'><SmallText tag="strong" className="bold">{ __('Payment Method') }:</SmallText></IonCol>
						<IonCol className="righted"><SmallText color="primary" className="thiner-text">{ visaEndingDrawText(__, basketInstance.formatPaymentMethod(this.props.cards, __, orderCompletePage)) }</SmallText></IonCol>
					</IonRow>
				</>
			)
		} else if (orderType === 'Delivery') {
			return (
				<>
					<IonRow>
						<IonCol size="5" className='paddLR'><SmallText tag="strong" className="bold">{ __('Delivery from') }:</SmallText></IonCol>
						<IonCol className="righted"><SmallText color="primary" className="thiner-text">{ order.delivery_address.addressLine1 },  { order.delivery_address.place }, { order.delivery_address.postalCode }</SmallText></IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="5" className='paddLR'><SmallText tag="strong" className="bold">{ __('Delivery at') }:</SmallText></IonCol>
						<IonCol className="righted"><SmallText color="primary" className="thiner-text">{ basketInstance.formatOrderTime(true) }</SmallText></IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="5" className='paddLR'><SmallText tag="strong" className="bold">{ __('Order placed on:') }</SmallText></IonCol>
						<IonCol className="righted"><SmallText color="primary" className="thiner-text">{ moment(order.created_at).format('ddd DD MMMM YYYY [at] LT') }</SmallText></IonCol>
					</IonRow>
					{order && order.amount_paid > 0 ?
						<IonRow>
							<IonCol size="5" className='paddLR'><SmallText tag="strong" className="bold">{ __('Payment Method') }:</SmallText></IonCol>
							<IonCol className="righted"><SmallText color="primary" className="thiner-text">{ visaEndingDrawText(__, basketInstance.formatPaymentMethod(this.props.cards, __, orderCompletePage)) }</SmallText></IonCol>
						</IonRow>
						: null
					}
					{ order && order.status === 'REFUNDED' ?
						<IonRow>
							<IonCol size="5" className='paddLR'><SmallText tag="strong" className="bold">{ __('Order Status')}:</SmallText></IonCol>
							<IonCol className="righted"><SmallText color="primary" className="thiner-text">{'Refunded'}</SmallText></IonCol>
						</IonRow>
						: ''
					}
				</>
			)
		} else if (orderType === 'Outpost Drop-Off') {
			return (
				<>
					<IonRow>
						<IonCol size="5" className='paddLR'><SmallText tag="strong" className="bold">{ __('Drop-off at') }:</SmallText></IonCol>
						<IonCol className="righted"><SmallText color="primary" className="thiner-text">{ order.restaurant_name }</SmallText></IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="5" className='paddLR'><SmallText tag="strong" className="bold">{ __('Order Placed') }:</SmallText></IonCol>
						<IonCol className="righted"><SmallText color="primary" className="thiner-text">{ moment(order.created_at).format('ddd DD MMMM YYYY [at] LT') }</SmallText></IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="5" className='paddLR'><SmallText tag="strong" className="bold">{ __('Drop-off Time') }:</SmallText></IonCol>
						<IonCol className="righted"><SmallText color="primary" className="thiner-text">{ basketInstance.formatOrderTime(true) }</SmallText></IonCol>
					</IonRow>
					{order && order.amount_paid > 0 ?
						<IonRow>
							<IonCol size="5" className='paddLR'><SmallText tag="strong" className="bold">{ __('Payment Method') }:</SmallText></IonCol>
							<IonCol className="righted"><SmallText color="primary" className="thiner-text">{ visaEndingDrawText(__, basketInstance.formatPaymentMethod(this.props.cards, __, orderCompletePage)) }</SmallText></IonCol>
						</IonRow>
						: null
					}
					{ order && order.status === 'REFUNDED' ?
						<IonRow>
							<IonCol size="5" className='paddLR'><SmallText tag="strong" className="bold">{ __('Order Status')}:</SmallText></IonCol>
							<IonCol className="righted"><SmallText color="primary" className="thiner-text">{'Refunded'}</SmallText></IonCol>
						</IonRow>
						: ''
					}
				</>
			)
		} else if (orderType === 'Table') {
			return (
				<>
					<IonRow>
						<IonCol size="5" className='paddLR'><SmallText tag="strong">{ __('Ordered at') }:</SmallText></IonCol>
						<IonCol className="righted self-aligned"><SmallText>{ order.restaurant_name }</SmallText></IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="5" className='paddLR'><SmallText tag="strong">{ __('Table number') }:</SmallText></IonCol>
						<IonCol className="righted self-aligned"><SmallText>{ order.table_name }</SmallText></IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="5" className='paddLR'><SmallText tag="strong">{ __('Order Placed') }:</SmallText></IonCol>
						<IonCol className="righted self-aligned"><SmallText>{ moment(order.created_at).format('ddd DD MMMM YYYY [at] LT') }</SmallText></IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="5" className='paddLR'><SmallText tag="strong">{ __('Payment Method') }:</SmallText></IonCol>
						<IonCol className="righted self-aligned"><SmallText>{ visaEndingDrawText(__, basketInstance.formatPaymentMethod(this.props.cards, __, orderCompletePage)) }</SmallText></IonCol>
					</IonRow>
				</>
			)
		}
	}

	render () {
		const { __, location } = this.props
		const order = location.state.order
		const { id, status } = order
		const basketInstance = createNewBasketInstance()
		basketInstance.recreateOrder(order)
		const orderType = basketInstance.getOrderType()
		const orderCompletePage = false
		const orderTypeTitle = basketInstance.getOrderType() === 'Click & Collect' ? 'Collection Order' : basketInstance.getOrderType()
		console.log('orderTypeTitle', orderTypeTitle)
		const hasCancelOrder = getConfig().appType.hasCancelOrder
		return (
			<Loading>
				<Layout headerTitle={ __('History') } color="background" backHandler = { this.backHandler }>
					<div className="absolute-content history-details">
						<div className="scrollable-y">
							<div className="order-status">{ __(status) }</div>
							<Spacer size={2} />
							<Title>{ this.drawContentTitle(__, Basket.getDeliveryOption(), id, orderTypeTitle) }</Title>
							{/* <SmallText color="gray">{ __(basketInstance.getOrderType()) } { __('Order') }  #{ order.id }</SmallText> */}
							<Spacer size={ 3 }/>
							{/* <Box>
								<BoxHeader>
									<p className="light-text">
										<SmallText tag="strong">{ __('Order Location') }:</SmallText> <SmallText>{ order.restaurant_name }</SmallText><br />
										<SmallText tag="strong">{ __('Order Time') }:</SmallText> <SmallText>{ basketInstance.formatOrderTime() }</SmallText><br />
										<SmallText tag="strong">{ __('Payment Method') }:</SmallText> <SmallText>{ this.formatPaymentString(basketInstance.formatPaymentMethod(cards, __)) }</SmallText>
									</p>
								</BoxHeader> */}
							<IonGrid className="okx-box-header-grid">
								<NormalText className="bold">{ __('Order Details') }</NormalText>
								<>
									{/* <IonRow>
										<IonCol size="5"><SmallText tag="strong" className="bold">{ __('Drop-off at') }:</SmallText></IonCol>
										<IonCol className="righted"><SmallText color="primary" className="thiner-text">{ order.restaurant_name }</SmallText></IonCol>
									</IonRow>
									<IonRow>
										<IonCol size="5"><SmallText tag="strong" className="bold">{ __('Order Placed') }:</SmallText></IonCol>
										<IonCol className="righted"><SmallText color="primary" className="thiner-text">{ moment(order.created_at).format('ddd DD MMMM YYYY [at] LT') }</SmallText></IonCol>
									</IonRow>
									<IonRow>
										<IonCol size="5"><SmallText tag="strong" className="bold">{ __('Drop-off Time') }:</SmallText></IonCol>
										<IonCol className="righted"><SmallText color="primary" className="thiner-text">{ basketInstance.formatOrderTime() }</SmallText></IonCol>
									</IonRow>
									<IonRow className="no-border">
										<IonCol size="5"><SmallText tag="strong" className="bold">{ __('Payment Method') }:</SmallText></IonCol>
										<IonCol className="righted"><SmallText color="primary" className="thiner-text">{ basketInstance.formatPaymentMethod(this.props.cards, __) }</SmallText></IonCol>
									</IonRow>
									<Spacer size={3} /> */}
									{ this.drawContent(__, order, orderType, basketInstance, orderCompletePage) }
									<Spacer size={3} />
									<NormalText className="bold">{ __('Items Ordered') }</NormalText>
									<OrderContent basketInstance={ basketInstance } type="orderHistory"/>
									<AmountPaid order={ order } cards={ this.props.cards } />
								</>
							</IonGrid>
							{/* </Box> */}
							{orderType !== 'Click & Collect' && [ 'PAID', 'PARSED', 'NEW', 'new' ].indexOf(order.status) !== -1 && this.state.isCancel && hasCancelOrder ?
								<IonButton expand="block" fill="clear" className="link underlined" color="tertiary" onClick={() => this.handleCancelOrderModal(true) }>{ __('Cancel this order')}</IonButton> :
								null
							}
						</div>
					</div>
				</Layout>
				<IonAlert
					isOpen={ this.props.cancelOrderModal === true }
					onDidDismiss={ () => this.handleCancelOrderModal(false) }
					header={ __('Confirm') }
					message={ __('Do you want to cancel this order?') }
					buttons={[
						{
							text: __('Cancel'),
							role: 'cancel',
							cssClass: 'secondary'
						},
						{
							text: __('Remove'),
							handler: () => this.cancelOrder()
						}
					]}
				/>
			</Loading>
		)
	}
}

const mapStateToProps = store => {
	const orders = store.orders
	const { cancelOrderModal } = orders
	const { restaurants } = store.restaurants
	let lastOrder = null
	const orderHistory = orders.orderHistory
	if (orderHistory && orderHistory.length > 0) {
		lastOrder = orderHistory[0]
	}
	return {
		cards: orders.cards || [],
		lastOrder,
		restaurants: restaurants || [],
		cancelOrderModal
	}
}

export default connect(mapStateToProps)(withTranslation(HistoryDetails))
