import React, { Component } from 'react'
import { connect } from 'react-redux'
import { IonButton, IonGrid, IonRow, IonCol } from '@ionic/react'
import Layout from '../../components/layout'
import { withTranslation } from '../../lib/translate'
import { isDefined, isEmptyObject, forwardTo, goBack } from '../../lib/utils'
import Loading from '../../components/spinner'
import OrderList from './orderList'
import Basket from '../../lib/basket'
import { setScrollTop } from '../../store/actions'
import ContentHeader from '../../components/contentHeader'
import './index.css'
import smallBanner from '../../assets/images/small_banner.svg'

const { getDeliveryOption, getDeliveryAddress, getPickUpPoint } = Basket

class OrderPage extends Component {
	constructor(props) {
		super(props)
		this.state = {
			selectedCategory: 0,
			ikentooMenu: null,
			menuRefs: null,
			categoriesPositionTop: []
		}
		this.selectCategoryOnScroll = this.selectCategoryOnScroll.bind(this)
	}

	setIkentooMenu = menu =>
		this.setState({ ikentooMenu:(menu || Basket.items.length) === 0 && isEmptyObject(this.props.ikentooMenu) ? this.props.defaultMenu : this.props.ikentooMenu }, () => {
			let menuRefs = this.menuRefs(this.state.ikentooMenu)
			this.setState({ menuRefs })
		})

	componentDidMount() {
		this.setIkentooMenu()
	}

	shouldComponentUpdate(nextProps) {
		const prevMenuName = (Basket.items.length === 0 && isEmptyObject(this.props.ikentooMenu) ? nextProps.defaultMenu : nextProps.ikentooMenu || {}).menuName
		const currentMenuName = (Basket.items.length === 0 && isEmptyObject(this.props.ikentooMenu) ? this.props.defaultMenu : this.props.ikentooMenu || {}).menuName
		if (prevMenuName !== currentMenuName || nextProps.restaurantsUpdated !== this.props.restaurantsUpdated) {
			this.setIkentooMenu(Basket.items.length === 0 && isEmptyObject(this.props.ikentooMenu) ? nextProps.defaultMenu : nextProps.ikentooMenu)
		}
		return true
	}

	menuRefs = menuRefs => {
		return menuRefs && menuRefs.menuEntryGroups ? menuRefs.menuEntryGroups.reduce((acc, value, index) => {
			let name = `${value.name}_${index}`
			acc[name] = React.createRef()
			return acc
		}, {}) : {}
	}

	scrollTo = name => this.state.menuRefs[name].current.scrollIntoView()

	getClosestPosition = (arr, pos) => {
		let closest = 0
		arr.some(a => {
			if (a > pos) { return true }
			closest = a
			return false;
		})
		return closest
	}

	selectCategoryOnScroll = e => {
		e.preventDefault()
		const positionTop = e.target.scrollTop + document.querySelector('.order-categories-inner').clientHeight
		const { menuRefs } = this.state
		const positions = Object.keys(menuRefs).map((key) => {
			return menuRefs[key].current.offsetTop
		})
		let selectCategory = 0
		let closest = this.getClosestPosition(positions, positionTop)
		positions.forEach((item, i) => {
			if (item === closest && positionTop >= item) {
				selectCategory = i
			}
		})
		if (document) {
			const catDiv = document.querySelector('.order-categories-inner')
			if (catDiv && catDiv.childNodes && catDiv.childNodes[selectCategory]) {
				setTimeout(() => {
					catDiv.childNodes[selectCategory].scrollIntoView({
						behavior: 'smooth',
						inline: 'start'
					})
				}, 500)
			}
		}
		this.props.dispatch(setScrollTop(e.target.scrollTop))
		this.setState({ selectedCategory: selectCategory, scrollTop: e.target.scrollTop })
	}

	backHandler = () => {
		if (this.props.location && this.props.location.state && this.props.location.state.skipBackToThePreviousPage) {
			forwardTo('/delivery-options')
		} else {
			goBack()
		}
	}

	render() {
		const { __, scrollTop } = this.props
		const { selectedCategory, ikentooMenu } = this.state
		let categories = !isEmptyObject(ikentooMenu) ? ikentooMenu.menuEntryGroups : []
		const labelTitle = Basket.getOrderType() === 'Click & Collect' ? 'Click & Collect Order' : Basket.getOrderType()

		return (
			<Loading>
				<Layout noPadding scrollY={false} color="background" backHandler={ this.backHandler }>
					{ !isDefined(ikentooMenu) ? null :
						<div className="segment-holder">
							<div className="order-categories">
								{
									Basket.getPassedOrder() ?
										<ContentHeader __={ __ } deliveryOption={ getDeliveryOption() } orderType={ labelTitle } deliveryAddress={ getDeliveryAddress() } pickUpPoint={ getPickUpPoint() } />
										:
										null
								}
								<div className="order-categories-inner">
									{ isDefined(categories) && categories.map((category, index) => {
										return (
											<IonButton
												key={ index }
												size="small"
												fill="clear"
												className={ selectedCategory === index ? 'category-button active' : 'category-button' }
												onClick={ () => this.scrollTo(`${category.name}_${index}`, index) }>
												{ __(category.name) }
											</IonButton>
										)
									})}
								</div>
							</div>
							<div className="order-content">
								<OrderList scrollTopPosition={ scrollTop } selectCategoryOnScroll={ this.selectCategoryOnScroll } category={ !isEmptyObject(ikentooMenu) ? ikentooMenu : null } menuRefs={ this.state.menuRefs ? this.state.menuRefs : [] }/>
								{
									Basket.itemsCount() === 0 ?
										<div className="view-order-button">
											<img className="order-summary-banner" alt="small_banner" src={ smallBanner } width="100%"/>
										</div>
										: null
								}
								{
									Basket.itemsCount() > 0 ?
										<>
											<IonButton onClick={ () => forwardTo('/order-summary') } expand="block" className="index" color="secondary">
												<IonGrid>
													<IonRow>
														<IonCol>{ __('View Order') }</IonCol>
														<IonCol>{ Basket.itemsCount() }&nbsp;{ Basket.itemsCount() === 1 ? __('Item') : __('Items') }</IonCol>
														<IonCol>{ Basket._getTotal() }</IonCol>
													</IonRow>
												</IonGrid>
											</IonButton>
											<div className="view-order-button">
												<img className="order-summary-banner" alt="small_banner" src={ smallBanner } width="100%"/>
											</div>
										</>
										: null
								}
							</div>
						</div>
					}
				</Layout>
			</Loading>
		)
	}
}

const stateToProps = state => {
	const { auth } = state.common
	const { restaurants, ikentooMenu, defaultMenu, restaurantsUpdated } = state.restaurants
	const { scrollTop, deliveryOption } = state.orders
	return {
		auth,
		restaurants: restaurants || [],
		ikentooMenu: ikentooMenu || {},
		defaultMenu: defaultMenu || {},
		basketUpdated: state.orders.basketUpdated,
		restaurantsUpdated,
		scrollTop,
		deliveryOption
	}
}

export default connect(stateToProps)(withTranslation(OrderPage))
