import React from 'react'
import { IonButton, /*, isPlatform */ IonIcon } from '@ionic/react'
import { connect } from 'react-redux'
import Loading from '../../components/spinner'
import Layout from '../../components/layout'
import Modal from '../../components/modal'
import { Title, Spacer, NormalText, SmallText } from '../../components/common'
import { isDefined, formatNumber } from '../../lib/utils'
import { withTranslation } from '../../lib/translate'
import { forwardTo } from '../../lib/utils'
import QRCode from 'qrcode-react'
import moment from '../../lib/moment'
import { lockClosed } from 'ionicons/icons'
import './index.css'
// import voucherDummyImg from '../../assets/images/refer-a-friend.jpg'

const RewardItem = ({ reward, id, className, __, action, available_balance }) => {
	const rewardCard =
		<>
			<img alt="" src={ reward?.image } width="100%" height={ reward.image === null ? '200px' : '' } />
			{ available_balance < reward.cost ? <div className="reward-cover"><IonIcon icon={ lockClosed } color="white" /></div> : null }
			{ action ?
				<div className="reward-bar">
					<div className="ellipsis">
						{/* <p className="ellipsis">{ __('Our Great New Voucher Deal!')}</p> */}
						{/* <p className="ellipsis">{ __(`Purchase for ${reward.reward.small_print} Points`) }</p> */}
						<p className="ellipsis">{ __(`${reward.small_print}`) }</p>
					</div>
					<div>
						{/* <IonButton fill="outline" size="small" onClick={() => action(id)}>{ __('View')}</IonButton> */}
						<IonButton fill="outline" size="small" onClick={() => action(id)}>{ __('Redeem') + reward.cost + __('PTS')}</IonButton>
					</div>
				</div>
				: null }
		</>

	return (
		<div className={'reward' + (className ? ' ' + className : '')}>
			<>
				{ rewardCard }
			</>
		</div>
	)
}

class UnlockRewardItmes extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			selectedReward: null
		}
	}

	viewReward = (id) => {
		const { rewards } = this.props
		this.setState({ selectedReward: rewards.find((r) => r.id === id) })
	}

	render() {
		const { __, qr_code, available_balance, rewards } = this.props
		const { selectedReward } = this.state
		return (
			<Loading transparent>
				<Layout color headerTitle={ __('{Vouchers}') } scrollY={false} hideBack = { true }>
					<div className="absolute-content unlock-rewards flex-row-wrapper">
						<div className="scrollable-y">
							<div className="flex-min ion-text-center">
								<div className="big-number">{ formatNumber(available_balance) }</div>
								<Title>{ __('Loyalty Points')}</Title>
								<Spacer size={1}/>
							</div>
							{(rewards || []).map((reward, index) => {
								return (
									<RewardItem key={ 'rw-' + index } { ...reward } __={ __ } available_balance={ available_balance } action={ () => this.viewReward(reward.id) }/>
								)
							})}
							<Spacer size={2}/>
						</div>
					</div>
					{/* {!isPlatform('ios') ? clickupContent : null} */}
				</Layout>
				{/* {isPlatform('ios') ? clickupContent : null} */}
				<Modal
					className="reward-modal"
					isOpen={ selectedReward !== null }
					onDidDismiss={() => this.setState({ selectedReward: null })}
					title={ __('Voucher Info')}>
					{ selectedReward || selectedReward?.reward || selectedReward?.reward?.reward ?
						<div className="centered">
							<img alt="" src={ selectedReward?.image || selectedReward?.reward?.image || selectedReward?.reward?.reward?.image } width="100%" height={ selectedReward?.image || selectedReward?.reward?.image || selectedReward?.reward?.reward?.image !== null ? '200px' : '' } />
							<Spacer size={2} />
							<div className="reward-modal-content">
								{ selectedReward?.name || selectedReward?.reward?.name || selectedReward?.reward?.reward?.name ?
									<>
										<Title className="uppercase">{ selectedReward?.name || selectedReward?.reward?.name || selectedReward?.reward?.reward?.name }</Title>
										<Spacer/>
									</>
									: null }
								{ selectedReward.reward.small_print || selectedReward.reward.reward.small_print ?
									<div><NormalText>{ selectedReward.reward.small_print || selectedReward.reward.reward.small_print }</NormalText></div>
									: null }
								{ selectedReward?.expiry_date || selectedReward?.reward?.expiry_date || selectedReward?.reward?.reward?.expiry_date ?
									<div><NormalText>{ __('Expires') + ':'} { moment(selectedReward?.expiry_date || selectedReward?.reward?.expiry_date || selectedReward?.reward?.reward?.expiry_date, 'YYYY/MM/DD').format('DD/MM/YY')}</NormalText></div>
									: null }
								<Spacer size={1} />
								<div onClick={ () => forwardTo('/terms') }><NormalText className="bold">{ __('Terms & Conditions')}</NormalText></div>
								<Spacer size={1} />
								{ selectedReward?.description || selectedReward?.reward?.description || selectedReward?.reward?.reward?.description ?
									<div><SmallText>{__(selectedReward?.description || selectedReward?.reward?.description || selectedReward?.reward?.reward?.description)}</SmallText></div>
									: null }
								<>
									<Spacer/>
									{isDefined(qr_code) ?
										<div className="qr-holder">
											<QRCode value={qr_code} size={200} />
										</div>
										:
										<div className='noQrCode'><h5>{__('NO QR CODE')}</h5></div>
									}
									<Spacer/>
								</>
							</div>
						</div>
						: null }
				</Modal>
			</Loading>
		)
	}
}

const stateToProps = state => {
	return {
		vouchers: state.profile.vouchers || [],
		qr_code: state.profile.profile.qr_code
	}
}

export default connect(stateToProps)(withTranslation(UnlockRewardItmes))
