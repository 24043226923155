export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGOUT = 'LOGOUT'
export const REGISTER_REQUEST = 'REGISTER_REQUEST'
export const GET_PROFILE = 'GET_PROFILE'
export const SET_PROFILE_PROP = 'SET_PROFILE_PROP'
export const UPDATE_PROFILE = 'UPDATE_PROFILE'
export const RESTORE_AUTH = 'RESTORE_AUTH'
export const SET_MODAL = 'SET_MODAL'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const SET_PROTECTED_REFERRER = 'SET_PROTECTED_REFERRER'
export const SET_SYS_LOCALE = 'SET_SYS_LOCALE'
export const VALIDATE_EMAIL = 'VALIDATE_EMAIL'
export const SET_REGISTER_FORM = 'SET_REGISTER_FORM'
export const CLEAR_REGISTER_FORM = 'CLEAR_REGISTER_FORM'
export const SET_VALIDATE_MODAL = 'SET_VALIDATE_MODAL'
export const SEND_REFER = 'SEND_REFER'
export const SEND_VOUCHER_CODE = 'SEND_VOUCHER_CODE'
export const GET_VOUCHERS = 'GET_VOUCHERS'
export const SET_VOUCHERS = 'SET_VOUCHERS'
export const CHECKOUT_DISCOUNT_VOUCHERS = 'CHECKOUT_DISCOUNT_VOUCHERS'
export const REMOVE_VOUCHER = 'REMOVE_VOUCHER'
export const GET_REFERRED_FRIENDS = 'GET_REFERRED_FRIENDS'
export const DELETE_REFERRED_FRIEND = 'DELETE_REFERRED_FRIEND'
export const RESEND_REFER = 'RESEND_REFER'



