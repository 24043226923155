import React, { Component } from 'react'
import { connect } from 'react-redux'
import { IonCheckbox, IonTextarea, IonItem, IonList, IonLabel, IonRadio, IonRadioGroup, IonButton, IonIcon, IonAlert } from '@ionic/react'
import { arrowBack } from 'ionicons/icons'
import Layout from '../../components/layout'
import Incrementer from '../../components/incrementer'
import Pullup from '../../components/pullup'
import { PillGroup } from '../../components/pill'
import { SmallText, Sectiontitle, Spacer } from '../../components/common'
import { withTranslation } from '../../lib/translate'
import Basket from '../../lib/basket'
import { makeKey, isDefined, deepCopy, goBack, isWebConfig, isEmptyObject, forwardTo, parseAllergenData } from '../../lib/utils'
import { getConfig } from '../../appConfig'
import { storeItemWeb } from '../../store/actions'

import './index.css'

import footerImg from '../../assets/images/footer-img.svg'

const { formatPrice, _calculateItemPrice, validateItem, addToBasket, getProductName, getProductDescription, isProductEnabled, isChoicesGroupValid, setAllergen } = Basket

const noImage = () => <div className="item-details-background"></div>

class itemDetailsContent extends Component {
	constructor(props) {
		super(props)
		this.state = {
			quantity: 1,
			price: 0,
			selectedChoices: [],
			validationErrors: [],
			instructions: '',
			showValidationAlert: false,
			allergensCodes: []
		}
	}

	componentDidMount() {
		const { profile, allergens } = this.props
		let quantity = this.state.quantity
		const item = this.props.item
		let selectedChoices = []
		let validationErrors = []

		if (item.menuDealGroups && item.menuDealGroups.length > 0) {
			selectedChoices = Array(item.menuDealGroups.length).fill([])
			validationErrors = Array(item.menuDealGroups.length).fill(null)
		}
		let price = _calculateItemPrice({ item, quantity })
		let newArr = parseAllergenData(profile, item, allergens)
		this.setState({ price, selectedChoices, validationErrors, allergensCodes: newArr }, () => {
			// run initial validation (this should be commented if initial validation isnt necessary)
			const initialValidationErrors = validateItem(this.constructBasketItem())
			this.setState({ validationErrors: initialValidationErrors.errors })
		})
	}
	onIncrementerUpdate = newQuantity => {
		this.setState({ quantity: newQuantity, price: _calculateItemPrice({
			item: this.props.item,
			quantity: newQuantity,
			selectedChoices: this.state.selectedChoices
		}) })
	}

	handleInputChange = (groupIndex, choiceIndex, multiSelectionPermitted, event) => {
		const item = this.props.item
		const { quantity, selectedChoices } = this.state
		const allGroups = item.menuDealGroups ? item.menuDealGroups : []
		const selectedGroup = allGroups[groupIndex]

		if (selectedGroup) {
			const selectedChoice = selectedGroup.items[choiceIndex]
			let updatedSelectedChoices = deepCopy(selectedChoices)
			if (multiSelectionPermitted) {
				//checkbox
				if (event.target.checked) {
					updatedSelectedChoices[groupIndex].push(selectedChoice)
				} else {
					updatedSelectedChoices[groupIndex] = updatedSelectedChoices[groupIndex].filter(i => i.sku !== selectedChoice.sku)
				}
			} else {
				//radio
				if (!selectedChoice) {
					updatedSelectedChoices[groupIndex] = []
				} else {
					updatedSelectedChoices[groupIndex] = [ selectedChoice ]
				}
			}

			this.setState({ selectedChoices: updatedSelectedChoices }, () => {
				const { selectedChoices } = this.state
				//recalculate item price on every menu deal choice change
				this.setState({ price: _calculateItemPrice({ item, quantity, selectedChoices }) }, () => {
					const validationErrors = validateItem(this.constructBasketItem())
					this.setState({ validationErrors: validationErrors.errors })
				})
			})
		}
	}

	drawGroupChoices = (menuGroupItem, multiSelectionPermitted, groupIndex) => {
		const { items, max } = menuGroupItem
		const choices = items || []
		const { selectedChoices } = this.state
		const { profile, __ } = this.props
		const allChoices = choices.map((item, choiceIndex) => {
			const { sku, productPrice } = item
			const isChecked = !!(selectedChoices[groupIndex] || []).find(i => {
				return i.sku === sku
			})
			if (item.sku === undefined || !isProductEnabled(item)) {
				return <span key={item.sku + '_disabled'}/>
			}
			const shouldByDisabled = isDefined(max) && max > 0 && isDefined(selectedChoices[groupIndex]) && selectedChoices[groupIndex].length >= max && !isChecked
			const description = getProductDescription(item, profile)
			return (
				<IonItem lines="none" key={ makeKey(choiceIndex, sku, groupIndex) }>
					<div tabIndex="-1"></div>
					{ multiSelectionPermitted ?
						<IonCheckbox disabled={ shouldByDisabled } color="secondary" slot="start" checked={ isChecked } onIonChange={ event => {
							this.handleInputChange(groupIndex, choiceIndex, multiSelectionPermitted, event)
						}}/>
						:
						<IonRadio slot="start" className="details-radio" color="secondary" value={ choiceIndex } checked={ isChecked } />
					}
					<IonLabel className="ion-text-wrap">
						<Sectiontitle className="single-item item-details-product-label">{ __(getProductName(item, profile)) }</Sectiontitle>
						{ isDefined(description) && description !== '' ? <>
							<SmallText className="no-margin"><div dangerouslySetInnerHTML={{ __html: description.replace(/(<([^>]+)>)/gi, '') }} /></SmallText>
							<Spacer size="1"/>
						</> : null }
						{ isDefined(item.itemRichData) && isDefined(item.itemRichData.allergenCodes) && item.itemRichData.allergenCodes.length > 0 ? <>
							{/* <SmallText>{ __('Allergens') }</SmallText> */}
							<PillGroup items={ item.itemRichData.allergenCodes } borderColor="primary"/>
						</> : null }
					</IonLabel>
					<p>{ formatPrice(productPrice) }</p>
				</IonItem>
			)
		})
		if (multiSelectionPermitted) {
			return allChoices
		} else {
			//radio
			return (
				<IonRadioGroup allowEmptySelection={ true } onIonChange={ event => {
					this.handleInputChange(groupIndex, event.target.value, multiSelectionPermitted, event)
				}}>{ allChoices }</IonRadioGroup>
			)
		}
	}

	drawGroupLabel = (menuGroupItem, groupIndex) => {
		const { validationErrors } = this.state
		const { __ } = this.props
		const { description, min, max } = menuGroupItem
		const itemDontHaveMinMax =( !isDefined(min) && !isDefined(max) )|| (min === 0 && max === 0)
		// let minMaxlabel = (isDefined(min) ? __('min') + ':' + min : '') + (isDefined(max) ? ', ' + __('max') + ':' + max : '')
		let minMaxlabel = null

		if (isDefined(min) && isDefined(max)) {
			if ((min === 0 && max === 0) || ( min === 0 && max === -1)) {
				minMaxlabel = null
			}
			if (min === 0 && max > 0) {
				minMaxlabel = __('Select up to') + ' ' + max + ' ' + __('items (optional)')
			}
			if (min > 0 && min === max) {
				minMaxlabel = __('Select') + ' ' + min + ' ' + ( min === 1 ? __('option') : __('options') )
			}
			if ((min > 0 && max === 0) || max === -1) {
				minMaxlabel = __('Select at least') + ' ' + min + ' ' + ( min === 1 ? __('option') : __('options') )
			}
			if (min > 0 && max > 0 && min !== max) {
				minMaxlabel = __('Select between') + ' ' + min + ' ' + __('and') + ' ' + max + ' ' + __('options')
			}
		} else {
			minMaxlabel = null
		}

		return <>
			<IonItem>
				<div tabIndex="-1" className="sectiontitle">{ description }
					{
						itemDontHaveMinMax ? null : isDefined(minMaxlabel) && minMaxlabel !== '' ? <>
							<br/>
							<SmallText className={validationErrors[groupIndex] ? 'field-error' : ''}>{ minMaxlabel }</SmallText>
						</> : null
					}
				</div>
			</IonItem>
			{ itemDontHaveMinMax && validationErrors[groupIndex] ? <div className="field-error">{ __(validationErrors[groupIndex]) }</div> : null }
		</>
	}

	drawMenuDealGroups = (menuGroupItem, index) => {
		// multiSelectionPermitted = true  --> only one item must be selected
		const multiSelectionPermitted = menuGroupItem.multiSelectionPermitted
		if (isChoicesGroupValid(menuGroupItem)) {
			if (isDefined(multiSelectionPermitted)) {
				return (
					<div key={ index }>
						{ this.drawGroupLabel(menuGroupItem, index) }
						{ this.drawGroupChoices(menuGroupItem, multiSelectionPermitted, index) }
					</div>
				)
			} else {
				return this.drawGroupLabel(menuGroupItem)
			}
		}
	}

	constructBasketItem = () => {
		const { item } = this.props
		const { quantity, selectedChoices, instructions } = this.state

		return {
			item,
			quantity,
			selectedChoices,
			instructions
		}
	}

	addToOrder = () => {
		const newBasketItem = this.constructBasketItem()
		const validationErrors = validateItem(newBasketItem)
		const item = this.props.item

		if (validationErrors.errorCount > 0) {
			this.setState({ validationErrors: validationErrors.errors }, () => {
				this.setShowValidationAlert(true)
			})
		} else {
			if (Basket.items.length === 0 && isEmptyObject(this.props.ikentooMenu)) {
				this.props.dispatch(storeItemWeb(newBasketItem))
				forwardTo('/delivery-options')
			} else {
				addToBasket(newBasketItem)
				if (isWebConfig()) {
					this.props.closeModal()
				} else {
					goBack()
				}
			}
		}

		let allergensCodes = item && item.itemRichData && item.itemRichData.allergenCodes && item.itemRichData.allergenCodes.length > 0 ? item.itemRichData.allergenCodes : []
		if (allergensCodes.length > 0) {
			let allergensData = [
				{ allergens: this.state.allergensCodes },
				{	sku: item.sku }
			]
			if (isDefined(newBasketItem.selectedChoices)) {
				newBasketItem.selectedChoices.forEach(group => {
					group.forEach(item => {
						if (isDefined(item.itemRichData) && isDefined(item.itemRichData.allergenCodes) && item.itemRichData.allergenCodes.length > 0) {
							setAllergen([
								{ allergens: item.itemRichData.allergenCodes },
								{	sku: item.sku }
							])
						}
					})
				})
			}
			setAllergen(allergensData)
		}
	}

	instructionsChange = event => this.setState({ instructions: event.target.value })

	setShowValidationAlert = flag => this.setState({ showValidationAlert: flag })

	render() {
		const { __, profile, item } = this.props
		const { quantity, showValidationAlert, allergensCodes } = this.state
		const menuDealGroups = item.menuDealGroups ? item.menuDealGroups : []
		const isAddToOrderBtnValid = validateItem(this.constructBasketItem()).errorCount === 0
		return (
			<>
				<div className="item-details-card-content">
					<IonList className="item-details-card-list">
						{/* <IonItem lines="none">
								<Sectiontitle>{ __('Description') }</Sectiontitle>
							</IonItem> */}
						<IonItem lines="none">
							<div tabIndex="-1"></div>
							<SmallText color="primary" className="item-details-card-description" dangerouslySetInnerHTML={{ __html: getProductDescription(item, profile).replace(/(<([^>]+)>)/gi, '') }}></SmallText>
						</IonItem>
						{ allergensCodes.length > 0 ?
							<>
								<Spacer size="1"/>
								<Sectiontitle><strong>{ __('Allergens')}</strong></Sectiontitle>
								<IonItem lines="none">
									<div tabIndex="-1"></div>
									<PillGroup items={ allergensCodes } borderColor="primary" paddingTop={4}/>
								</IonItem>
							</>
							: null }
						{ menuDealGroups.map(this.drawMenuDealGroups) }
						{ getConfig().flags.specialInstructions.isDisplayed ?
							<>
								<IonItem lines="none">
									<div tabIndex="-1"></div>
									<Sectiontitle>{ __('Special Instructions') } <span className="normal-text">{ __('Optional') }</span></Sectiontitle>
								</IonItem>
								<IonItem>
									<IonTextarea onIonChange={ this.instructionsChange } rows={ 1 } placeholder={ __(getConfig().flags.specialInstructions.placeholder) }></IonTextarea>
								</IonItem>
							</> : null
						}
					</IonList>
				</div>
				<div className="item-details-actions">
					<Incrementer allowNegative={ false } quantity={ quantity } onUpdate= { this.onIncrementerUpdate }/>
					<IonButton disabled={ quantity === 0 || !isAddToOrderBtnValid } className={'item-details-add-to-order ' + (isAddToOrderBtnValid ? '' : 'greyed')} size="full" shape="round" color="secondary" onClick={ this.addToOrder }>
						{Basket.items.length === 0 && isEmptyObject(this.props.ikentooMenu) ? __('Start New Order') : __('Add to Order') } {/*{ price ? '-' : '' } { price }*/}
					</IonButton>
				</div>
				<IonAlert
					isOpen={ showValidationAlert }
					onDidDismiss={() => this.setShowValidationAlert(false)}
					header={ __('Validation') }
					message={ __('Please check any required options') }
					buttons={[{ text: __('OK'), role: 'cancel', cssClass: 'secondary' }]}
				/>
			</>
		)
	}
}

const mapStateToProps = store => {

	return {
		profile: store.profile.profile,
		restaurantsUpdated: store.restaurants.restaurantsUpdated,
		basketUpdated: store.orders.basketUpdated,
		allergens: store.restaurants.allergens,
		storedItemWeb: store.orders.storedItemWeb,
		ikentooMenu: store.restaurants.ikentooMenu || {}
	}
}

export const ItemDetailsRaw = connect(mapStateToProps)(withTranslation(itemDetailsContent))

export class itemDetails extends Component {

	render() {
		const { __, location, history, profile } = this.props
		const item = location.state
		let image = item && item.itemRichData && item.itemRichData.squareImageUrl ? item.itemRichData.squareImageUrl : ''
		if (image && image.indexOf('http://') !== -1) {
			image = image.replace(/http:\/\//g, 'https://')
		}
		// const allergens = item.allergens || ['Celery (including celeriac)', 'Fish', 'Crustaceans', 'Lupin', 'Mustard']
		return (
			<Layout headerTitle={ __('Item Details') } scrollY={ false } noPadding contentClassName="item-details-wrapper" hideSecondToolbar={ true }>
				{image && image !== '' ?
					<div className="item-details-image" style={{ backgroundImage: 'url(' + image + ')' }}/> :
					noImage()
				}

				<Pullup className="item-details-card" offsetTop={ 55 } top={ 75 } offsetBottom={ window && window.innerHeight ? Math.round(window.innerHeight /2) + 20 : 220 } contentOffsetBottom={ 90 } header={(
					<div className="item-details-card-header flex-col-wrapper">
						<div className="title ellipsis">{ __(getProductName(item, profile)) }</div>
						<div className="item-details-card-price">{ formatPrice(item.productPrice) }</div>
					</div>)}>
					<ItemDetailsRaw item={ item } profile={ profile } />
				</Pullup>

				<div className="item-details-back">
					<IonButton className="solo-button" color="white" onClick={() => history.goBack() }>
						<IonIcon slot="icon-only" icon={ arrowBack }/>
					</IonButton>
				</div>
				<img width="100%" className="footer-img" src={ footerImg } alt="footer-img" />
			</Layout>
		)
	}
}

const stateToProps = store => {
	return {
		profile: store.profile.profile
	}
}

export default connect(stateToProps)(withTranslation(itemDetails))
