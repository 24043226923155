import React from 'react'
import { IonTitle, IonToolbar, IonButtons } from '@ionic/react'
import BackButton from '../backButton'
import './index.css'

const Header = ({ title, hideBack, backHandler, history }) => {
	const currentPath = history?.location?.pathname
	const specificDashboardHeader = currentPath === '/refer-a-friend' ? 'dynamic-header--refer-a-friend' : currentPath === '/vouchers' ? 'dynamic-header--vouchers' : currentPath === '/click-and-collect' ? 'dynamic-header--click-and-collect' : ''
	return (
		<IonToolbar className={`dynamic-header ${specificDashboardHeader}`}>
			{ !hideBack ?
				<IonButtons slot="start">
					<BackButton backHandler={ backHandler }/>
				</IonButtons>
				: null }
			<IonTitle className="ion-text-center" style={{ visibility: 'hidden' }}>{ title }</IonTitle>
			{ !hideBack ?
				<IonButtons slot="end">
					<BackButton style={{ visibility: 'hidden' }} backHandler={ backHandler }/>
				</IonButtons>
				: null }
		</IonToolbar>
	)
}

export default Header
