import React from "react";
import { IonButton, IonInput, IonItem, IonList, IonIcon, IonAlert } from "@ionic/react";
import Layout from "../../components/layout";
import Loading from "../../components/spinner";
import {
  Title,
  Spacer,
  FieldError,
  SmallText,
  NormalText,
  StrongText,
} from "../../components/common";
import { validateForm } from "../../lib/utils";
import { withTranslation } from "../../lib/translate";
import { deleteReferredFriend, getReferredFriends, resendRefer, sendRefer } from "../../store/actions";
import SwipableTabs from "../../components/swipeableTabs";
import moment from "../../lib/moment";
import { trashOutline, personOutline } from "ionicons/icons";
import "./index.css";
import { connect } from "react-redux";
class ReferAFriend extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      formErrors: {},
      referredFriends: [],
      removeReferredFriendModal: false,
      refferedFriendId: null
    };
    this.formConfig = {
      email: { type: "email", required: true },
    };
  }

  handleInput(key, val) {
    this.setState({ [key]: val });
  }
  componentDidMount() {
    const { dispatch} = this.props;
    dispatch(getReferredFriends());
  }
  handleRefer = () => {
    const { dispatch, __ } = this.props;
    let formErrors = validateForm(this.formConfig, this.state, __);
    this.setState({ formErrors });
    if (Object.keys(formErrors).length === 0) {
      const { email } = this.state;
      const data = { email: email };
      this.setState({ email: "" });
      dispatch(sendRefer(data));
    }
  };

  getExpireDate = (unformattedDate) => {
    const date = moment(unformattedDate)
    const expireDate = date.add("days", 11);
    const currentDate = moment();
    const daysLeft = expireDate.diff(currentDate, "DAYS");
    return daysLeft;
  };
  removeReferredFriend = () => {
    const data = this.state.refferedFriendId
    const { dispatch } = this.props;
    dispatch(deleteReferredFriend(data));
  }
  resendRefer = (email) => {
    const { dispatch } = this.props;
    const data = { email }
    dispatch(resendRefer(data));
  }
  render() {
    const { __, profile } = this.props;
    const { email } = this.state;
    const referredFriends = profile.referredFriends;
    const hasTabs = false
    return (
      <Loading>
        <Layout
          hideSecondToolbar={true}
          headerTitle={__("Refer and Earn")}
          color="transparent"
        >
          {hasTabs &&
            <SwipableTabs
              tabs={[
                {
                  label: __("Refer and Earn"),
                  tabContent: (
                    <>
                      <div className="scrollable-y refer-and-earn-wrapper">
                        <Title>{__("Refer and Earn")}</Title>
                        <Spacer size={2} />
                        <NormalText>
                          {__(
                            "You will be rewarded with 5 points for every £1 they spend. Make sure they sign up with the same email address to get rewarded."
                          )}
                        </NormalText>
                        <Spacer size={2} />

                        <IonItem>
                          <div className="inline-input">
                            <IonInput
                              onIonChange={(e) =>
                                this.handleInput("email", e.target.value)
                              }
                              required
                              placeholder={__("Enter your friend's email")}
                              type="email"
                              pattern="email"
                              inputmode="email"
                              value={email}
                            />
                          </div>
                        </IonItem>
                        <FieldError
                          className="field-error"
                          value={__(this.state.formErrors.email)}
                        />
                        <Spacer size={2} />
                        <IonButton
                          expand="block"
                          color="secondary"
                          strong={true}
                          onClick={() => this.handleRefer()}
                        >
                          {__("Send Invitation")}
                        </IonButton>
                      </div>
                    </>
                  ),
                },
                {
                  label: __("Activity"),
                  tabContent: (
                    <>
                      <IonList class="refered-friends-list">
                        {referredFriends.map((referredFriend) => {
                          return (
                            <IonItem id={referredFriend.id}>
                              <div>
                                <IonIcon
                                  color="primary"
                                  icon={personOutline}
                                ></IonIcon>
                                <div className="user-info-wrapper">
                                  <StrongText className='ref-email '>{referredFriend.email}</StrongText>
                                  {referredFriend.is_active ? (
                                    <SmallText className="refered-friend-success-message">
                                  {__('Active')}
                                    </SmallText>
                                  ) : (
                                    <SmallText>{__('Invite expires')}: {this.getExpireDate(referredFriend.date_referred)} days</SmallText>
                                  )}
                                </div>
                                <IonButton
                                  className="resend-invite-btn"
                                  disabled={referredFriend.is_active}
                                  color="secondary"
                                  onClick={() => { this.resendRefer(referredFriend.email) }}
                                >
                                  {__("Resend")}
                                </IonButton>
                                <IonButton
                                  className="delete-refered-friend-btn"
                                  color="danger"
                                  // onClick={() => { this.deleteReferredFriend(referredFriend.id) }}
                                  onClick={() => { this.setState({ removeReferredFriendModal: true, refferedFriendId: referredFriend.id }) }}
                                >
                                  <IonIcon
                                    icon={trashOutline}
                                    color="white"
                                  ></IonIcon>
                                </IonButton>
                              </div>
                            </IonItem>
                          );
                        })}
                      </IonList>
                    </>
                  ),
                },
              ]}

            />

          }
          {!hasTabs &&
           <>
           <div className="scrollable-y">
             <Title>{__("Refer a Friend")}</Title>
             <Spacer size={2} />
             <NormalText>
               {__(
                 "You will be rewarded with 5 points for every £1 they spend. Make sure they sign up with the same email address to get rewarded."
               )}
             </NormalText>
             <Spacer size={2} />

             <IonItem>
               <div className="inline-input">
                 <IonInput
                   onIonChange={(e) =>
                     this.handleInput("email", e.target.value)
                   }
                   required
                   placeholder={__("Enter your friend's email")}
                   type="email"
                   pattern="email"
                   inputmode="email"
                   value={email}
                 />
               </div>
             </IonItem>
             <FieldError
               className="field-error"
               value={__(this.state.formErrors.email)}
             />
             <Spacer size={2} />
             <IonButton
               expand="block"
               color="secondary"
               strong={true}
               onClick={() => this.handleRefer()}
             >
               {__("Send Invitation")}
             </IonButton>
           </div>
         </>
          
          }
        </Layout>
        {this.state.removeReferredFriendModal &&
          <IonAlert
            isOpen={true}
            onDidDismiss={() => this.setState({ removeReferredFriendModal: false, refferedFriendId: null })}
            header={__('Confirm')}
            message={__('Do  you want to remove this referred friend?')}
            buttons={[
              {
                text: __('Cancel'),
                role: 'cancel',
                cssClass: 'secondary',
              },
              {
                text: __('Remove'),
                handler: () => this.removeReferredFriend(),
              },
            ]}
          />
        }
      </Loading>
    );
  }
}
const mapStateToProps = (store) => {
  const profile = store.profile;

  return {
    profile,
  };
};
export default connect(mapStateToProps)(withTranslation(ReferAFriend));
