import React, { Component } from 'react'
import Basket from '../../lib/basket'
import { getConfig } from '../../appConfig'
import { withTranslation } from '../../lib/translate'
import { Title, Spacer, BigLabel, SmallText } from '../../components/common'
import UnlockRewardItems from '../../components/unlockRewardItems'
// import loyaltyImage from '../../assets/images/loyalty.svg'

export class Points extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isOpen: false,
			selectedIndex: null
		}
	}

	toggleModal = (val, index) => {
		this.setState({ isOpen: val, selectedIndex: index })
	}

	render() {
		const { __, available_balance, rewards, qr_code } = this.props
		return (
			<>
				{ getConfig().appType.hasUnlockRewardsOnLoyalty ?
					<UnlockRewardItems
						available_balance={ available_balance }
						rewards={ rewards }
						qr_code={ qr_code }
						__={ __ }
					/>
					:
					<div className="absolute-content points-tab">
						<div className="scrollable-y">
							<Spacer size={ 5 }/>
							<Title>{ __('Loyalty')}</Title>
							{/* <div className="loyalty-badge"> */}
							<Spacer size={ 2 }/>
							{/* <div>
								<img src={ loyaltyImage } />
							</div> */}
							<Spacer size={ 2 }/>
							{/* </div> */}
							<BigLabel className="points">{ available_balance.toLocaleString() }</BigLabel>
							{/* <NormalText>{ __('Points Balance')}</NormalText> */}
							{/* <Spacer size={1}/> */}
							{/* <SmallText>{ __('Worth') }&nbsp;{ Basket._calculatePointsAppliedPrice(available_balance, false, true) }</SmallText> */}
							<SmallText className="heading">{ __('points worth') } <strong>&nbsp;{ Basket._calculatePointsAppliedPrice(available_balance, false, true) }</strong></SmallText>
						</div>
					</div>
				}
			</>
		)
	}
}

export default withTranslation(Points)
